import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '@scpc/modules/common/components/button/button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'scp-header-update',
  templateUrl: './header-update.component.html',
  styleUrls: ['./header-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ButtonComponent],
})
export class HeaderUpdateComponent {

  /* istanbul ignore next */
  public reload(): void {
    location.reload();
  }

}
