import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { AuthenticationService, LayoutService, ScpService, StorageService } from '@scpc/modules/common/services';
import { Customer } from '@scpc/dto/customer';
import {
  ActivatedRoute,
  Data,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Params,
  ResolveEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { auditTime, filter, first, map, pairwise, skip, startWith, take } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { Balance, SmartBannerConfig, Withdrawal, WithdrawalConfiguration, WithdrawalType } from '@scpc/dto';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { AsyncPipe, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BonusProgramOffersStats } from '@scpc/dto/bonus-program-offer';
import { ScpWebSocketService } from '@scpc/modules/common/services/scp.websocket.service';
import { GoogleTagManagerService } from '@scpc/modules/common/services/analytics/google-tag-manager.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ScpTour, ScpTourService } from '@scpc/modules/common/services/scp.tour.service';
import { CheckForUpdateService } from '@scpc/modules/common/services/check-for-update.service';
import { InboxService } from '@scpc/modules/common/services/inbox.service';
import { CookiesService } from '@scpc/modules/cookies/cookies.service';
import { getHost } from '@scpc/utils/host.utils';
import { Idle } from '@scpc/modules/common/services/request-idle-callback';
import { parseJWT } from '@scpc/utils/jwt.utils';
import { forkJoin, fromEvent, Observable, Subscription, timer } from 'rxjs';
import { HeaderComponent } from '@scpc/modules/common/components/header/header.component';
import { MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatListItem, MatNavList } from '@angular/material/list';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { SkeletonLoaderComponent } from '@scpc/modules/common/components/skeleton-loader/skeleton-loader.component';
import { CartButtonComponent } from '@scpc/modules/cart/components/cart-button/cart-button.component';
import { TitleComponent } from '@scpc/modules/common/components/title/title.component';
import { FooterComponent } from '@scpc/modules/common/components/footer/footer.component';
import { HeaderCookiesComponent } from '@scpc/modules/common/components/header-cookies/header-cookies.component';
import { HeaderUpdateComponent } from '@scpc/modules/common/components/header-update/header-update.component';
import { ProductsComponent } from '@scpc/modules/common/components/products/products.component';
import {
  DashboardMenuItemComponent,
} from '@scpc/modules/common/components/dashboard-menu-item/dashboard-menu-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormatMoneyPipe } from '@scpc/modules/common/pipes/format-money.pipe';
import { ButtonComponent } from '@scpc/modules/common/components/button/button.component';
import { PageData } from '@scpc/modules/common/resolvers/page.resolver';
import { SeoComponent } from '@scpc/modules/common/components/seo/seo.component';
import { SmartBannerComponent } from '@scpc/modules/common/components/smart-banner/smart-banner.component';
import {
  SourceOfFundsDialogService,
} from '@scpc/modules/common/components/source-of-funds/source-of-funds.dialog.service';
import { DialogRef } from '@angular/cdk/dialog';
import { SourceOfFundsComponent } from '@scpc/modules/common/components/source-of-funds/source-of-funds.component';
import { ResizeObserverDirective } from '@scpc/modules/common/directives/resize-observer.directive';
import { BookmakersService } from '@scpc/modules/common/services/bookmakers.service';

export interface CategoryItem {
  routerLink: string;
  queryParams?: { [key: string]: string | string[] };
  title: string;
  icon: string;
  iconSize?: { width: number, height: number },
  isActive: boolean;
  count?: number;
}

export interface Category {
  title: string;
  logo: string;
  routerLink?: string;
  queryParams?: { [key: string]: string | string[] };
  closed: boolean;
  isActive?: boolean;
  items?: CategoryItem[];
}

@Component({
  selector: 'scp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    AsyncPipe,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    ResizeObserverDirective,
    TranslateModule,
    MatSidenav,
    MatSidenavContainer,
    MatSidenavContent,
    MatMiniFabButton,
    MatIconButton,
    MatNavList,
    MatListItem,
    LoadingBarHttpClientModule,
    CartButtonComponent,
    HeaderComponent,
    TitleComponent,
    SkeletonLoaderComponent,
    FooterComponent,
    HeaderCookiesComponent,
    HeaderUpdateComponent,
    ProductsComponent,
    DashboardMenuItemComponent,
    FormatMoneyPipe,
    ButtonComponent,
    SeoComponent,
    SmartBannerComponent,
  ],
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(HeaderComponent)
  private header: HeaderComponent;

  @ViewChild(MatSidenav, { static: true })
  private sidenav: MatSidenav;

  @ViewChild('dashboardScrollingBlock', { read: ElementRef, static: true })
  private scrollbar: ElementRef<HTMLDivElement>;

  @ViewChild('top', { read: ElementRef, static: true })
  private top: ElementRef<HTMLDivElement>;

  public readonly isBrowser: boolean = isPlatformBrowser(this.platformId);
  public readonly isDesktop: boolean = this.layoutService.isDesktop();

  public isWeb: boolean = this.breakpointObserver.isMatched('(min-width: 961px)');
  public isFloatCart: boolean = this.breakpointObserver.isMatched('(max-width: 769px)');

  public isFloatCartAllowed = true;
  public isAuthorized = !!this.storageService.accessToken;
  public customer: Customer | null = null;
  public balance: Balance | null = null;
  public isLoaded = false;
  public layoutClass = null;
  public showCookiesHeader;
  public headerClass = null;
  public toTop = false;
  public seo: string | null = null;
  public isVisibleSkeletonLoader = false;
  public hasFooter = false;
  public tours: ScpTour[] = [];
  public restoreTour = false;
  public restoreTourAfterNavigation = this.scpTourService.isDefaultTourAvailable();
  protected readonly logo: string = `/assets/images/svg/${this.configService.normalizedSiteName}.svg`;
  protected readonly smallLogo: string = `/assets/images/svg/${this.configService.normalizedSiteName}-logo.svg`;
  protected smartBannerConfig: SmartBannerConfig;
  private menuCategories: Category[] | null = null;
  private readonly host: string;
  private tourTime: Subscription = null;
  private sourceOfFunds: boolean;
  private sourceOfFundsDialogRef: DialogRef<unknown, SourceOfFundsComponent>;

  private EXCLUDED_STATUSES: string [] = ['SELF_EXCLUSION', 'DECEASED', 'DUPLICATE_ID_ARCHIVED'];

  constructor(
    public readonly activatedRoute: ActivatedRoute,
    public readonly loader: LoadingBarService,
    public readonly update: CheckForUpdateService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly authenticationService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly storageService: StorageService,
    private readonly scpService: ScpService,
    private readonly layoutService: LayoutService,
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly idle: Idle,
    private readonly cookiesService: CookiesService,
    private readonly scpWebSocketService: ScpWebSocketService,
    private readonly googleTagManagerService: GoogleTagManagerService,
    private readonly scpTourService: ScpTourService,
    private readonly inboxService: InboxService,
    private readonly sourceOfFundsDialogService: SourceOfFundsDialogService,
    private readonly bookmakersService: BookmakersService,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Inject(DOCUMENT) protected readonly document: Document,
    @Optional() @Inject('HOST') host: string,
    protected readonly configService: ConfigService,
  ) {
    this.host = host || getHost(this.document.defaultView);
  }

  public get categories(): Category[] {
    return this.menuCategories?.filter((c: Category) => c.isActive !== false);
  }

  public get signOutURL(): string {
    const url: string = this.router.url;
    for (const part of ['/account', '/betgames', '/promotions']) {
      if (url.includes(part)) {
        return '/sign-in';
      }
    }
    return this.router.url;
  }

  public get kycRequired(): boolean {
    return this.isAuthorized &&
      this.customer &&
      !this.isVisibleSkeletonLoader &&
      !this.EXCLUDED_STATUSES.includes(this.customer.status) &&
      this.customer.ekyc?.status !== 'CONFIRMED' &&
      this.customer.kyc?.status !== 'CONFIRMED' &&
      !this.router.url.startsWith('/account/profile/kyc') &&
      !this.router.url.startsWith('/account/profile/phone') &&
      !this.router.url.includes('self-exclusion') &&
      !this.router.url.startsWith('/account/wallet/withdrawals');
  }

  /* istanbul ignore next */
  public get phoneRequired(): Observable<boolean> {
    return this.storageService.getWithdrawalConfiguration()
      .pipe(
        filter(a => !!a),
        take(1),
        map((configuration: WithdrawalConfiguration): boolean => {
            const isWithdrawalsPage = this.router.url.startsWith('/account/wallet/withdrawals');
            return this.isAuthorized &&
              this.customer &&
              !this.EXCLUDED_STATUSES.includes(this.customer.status) &&
              !this.router.url.startsWith('/account/profile/phone') &&
              ((!!configuration.methods.find((m: Withdrawal): boolean => m.phoneRequired) && isWithdrawalsPage) || (this.customer.status === 'DUPLICATE_ID' && !this.customer.contactDetails.mobileNumberVerified)) &&
              !this.isVisibleSkeletonLoader;
          },
        ),
      );
  }

  /* istanbul ignore next */
  protected get documentsRequired(): Observable<boolean> {
    return this.storageService.getWithdrawalConfiguration()
      .pipe(
        filter(a => !!a),
        take(1),
        map((configuration: WithdrawalConfiguration): boolean =>
          this.isAuthorized &&
          this.customer &&
          !this.EXCLUDED_STATUSES.includes(this.customer.status) &&
          this.router.url.startsWith('/account/wallet/withdrawals') &&
          !!configuration.methods.find((m: Withdrawal): boolean => !m.phoneRequired) &&
          !!configuration.methods.find((m: Withdrawal): boolean => m.documentsRequired),
        ),
      );
  }

  private static createCategories(configService: ConfigService): Category[] {
    return [
      {
        title: 'MY_WALLET',
        logo: 'wallet',
        closed: true,
        items: [
          {
            routerLink: '/account/wallet/deposits',
            title: 'TITLE.MAKE_DEPOSIT',
            icon: 'plus-green',
            isActive: true,
          },
          {
            routerLink: '/account/wallet/withdrawals',
            title: 'TITLE.MAKE_WITHDRAWAL',
            icon: 'minus',
            isActive: true,
          },
          {
            routerLink: '/account/wallet/ctt',
            title: 'TITLE.MY_CREDIT_TOKENS',
            icon: 'credit-token',
            isActive: false,
          },
        ],
      },
      {
        title: 'MY_STATEMENT',
        logo: 'statements',
        routerLink: '/account/statements',
        closed: true,
      },
      {
        title: 'MY_WAGERS',
        logo: 'no-bets',
        closed: true,
        items: [
          {
            routerLink: '/account/wagers/placed',
            queryParams: {
              status: 'open',
            },
            title: 'WAGERS_PAGE.OPEN',
            icon: '/wagers/open',
            iconSize: {
              width: 21,
              height: 16,
            },
            isActive: true,
          },
          {
            routerLink: '/account/wagers/placed',
            queryParams: {
              status: 'settled',
            },
            title: 'WAGERS_PAGE.SETTLED',
            icon: '/wagers/settled',
            isActive: true,
          },
          {
            routerLink: '/account/wagers/subscriptions',
            title: 'WAGERS_PAGE.PENDING',
            icon: '/wagers/pending',
            isActive: true,
          },
        ],
      },
      {
        title: 'PROMOTIONS',
        logo: 'promotions-black',
        routerLink: '/promotions',
        closed: true,
        isActive: !!configService.products.find((p: { type: string }): boolean => p.type === 'PROMOTIONS'),
      },
      {
        title: 'MY_BONUSES',
        logo: 'bonus-offers',
        closed: true,
        items: [
          {
            routerLink: '/account/bonus-programs-offers/not-accepted',
            title: 'BONUS_OFFERS',
            icon: 'bonuses-offers-active',
            isActive: true,
          },
          {
            routerLink: '/account/bonus-programs-offers/accepted',
            title: 'ACTIVE_BONUS',
            icon: 'bonuses-offers-accepted',
            isActive: true,
          },
          {
            routerLink: '/account/bonus-programs-offers/completed',
            title: 'BONUSES_COMPLETED',
            icon: 'bonuses-offers-completed',
            isActive: true,
          },
          {
            routerLink: '/account/bonus-programs-offers/failed',
            title: 'BONUSES_FAILED',
            icon: 'bonuses-offers-failed',
            isActive: true,
          },
        ],
      },
      {
        title: 'REFER_A_FRIEND',
        logo: 'refer-a-friend',
        routerLink: '/account/bonus-programs-offers/',
        closed: true,
        isActive: false,
      },
      {
        title: 'TITLE.SETTINGS',
        logo: 'settings',
        closed: true,
        items: [
          {
            title: 'TITLE.NOTIFICATIONS',
            icon: 'notification',
            routerLink: '/account/settings/notifications',
            isActive: true,
          },
        ],
      },
      {
        title: 'TITLE.FAQ',
        logo: 'faq',
        closed: true,
        isActive: configService.hasFAQ,
        routerLink: '/faq',
      },
      {
        title: 'TITLE.BLOG',
        logo: 'blog',
        closed: true,
        isActive: configService.hasBlog,
        routerLink: '/blog',
      },
    ];
  }

  private static createNotAuthorizedCategories(configService: ConfigService): Category[] {
    return [
      {
        title: 'PROMOTIONS',
        logo: 'promotions-black',
        routerLink: '/promotions',
        closed: true,
        isActive: !!configService.products.find((p: { type: string }): boolean => p.type === 'PROMOTIONS'),
      },
      {
        title: 'TITLE.BLOG',
        logo: 'blog',
        closed: true,
        isActive: configService.hasBlog,
        routerLink: '/blog',
      },
      {
        title: 'TITLE.AFFILIATE_PROGRAM',
        logo: 'affiliate-program',
        closed: true,
        isActive: !!configService.affiliateProgram,
        routerLink: configService.affiliateProgram,
      },
      {
        title: 'TITLE.RESPONSIBLE_GAMBLING',
        logo: 'responsible-gambling-policy',
        closed: true,
        isActive: configService.hasResponsibleGambling,
        routerLink: '/responsible-gambling-policy',
      },
      {
        title: 'TITLE.TERMS_AND_CONDITIONS',
        logo: 'terms',
        closed: true,
        isActive: configService.hasTerms,
        routerLink: '/terms',
      },
      {
        title: 'TITLE.ABOUT_US',
        logo: 'about-us',
        closed: true,
        isActive: configService.hasAboutUs,
        routerLink: '/about-us',
      },
      {
        title: 'TITLE.FAQ',
        logo: 'faq',
        closed: true,
        isActive: configService.hasFAQ,
        routerLink: '/faq',
      },
    ];
  }

  public ngOnInit(): void {
    this.authenticationService.authorization.subscribe((value) => this.updateAuthorizationStatus(value));
    this.initLayout();
    this.authenticationService.isAuthorized().subscribe((value) => this.updateAuthorizationStatus(value));
  }

  public ngOnDestroy() {
    this.scpWebSocketService.disconnect();
  }

  public async ngAfterViewInit(): Promise<void> {
    this.storageService.setScrollbar(this.scrollbar);
    if (this.isBrowser) {
      fromEvent(this.scrollbar.nativeElement, 'scroll')
        .pipe(auditTime(10), map(/* istanbul ignore next */ (e: Event) => (e.target as Element).scrollTop))
        .subscribe(/* istanbul ignore next */  (scrollTop: number) => this.onResize(scrollTop));

      const snapshot = this.activatedRoute.snapshot;
      const tourName = snapshot.queryParams.tour?.toLowerCase();
      let tour: ScpTour;

      if (tourName) {
        const params = { ...snapshot.queryParams };
        delete params.tour;
        await this.router.navigate([], { queryParams: params });
        tour = this.tours.find(t => t.name.toLowerCase() === tourName);
      }

      if (tour) {
        await this.scpTourService.showTourIfNotCompleted(tour);
      } else {
        this.updateDefaultTourState();
      }

      this.sidenav.openedChange.subscribe(/* istanbul ignore next */ () => {
        if (this.restoreTour && !this.sidenav.opened) {
          this.scpTourService.showDefaultTour(true);
        }
      });
    }
    this.googleTagManagerService.addGtmToDom();
  }

  public acceptCookies(): void {
    this.storageService.saveCookiesAccepted();
    this.showCookiesHeader = false;
  }

  public async closeSidenav(): Promise<void> {
    /* istanbul ignore next */
    if (this.sidenav.mode === 'over') {
      await this.sidenav.close();
    }
  }

  public async signOut(): Promise<void> {
    this.authenticationService.signOut();
    this.updateCategories(true);
    this.isLoaded = false;
    this.closeSidenav();
    this.scpWebSocketService.off('profile');
    this.scpWebSocketService.off('balance');
    this.scpWebSocketService.off('facebook');
  }

  public showTour(tour: ScpTour): void {
    this.scpTourService.showTourIfNotCompleted(tour);
  }

  public hideTour(): void {
    this.scpTourService.hideDefaultTour();
    this.restoreTour = false;
  }

  /* istanbul ignore next */
  public async toggleSidenav() {
    if (!this.sidenav.opened) {
      this.restoreTour = this.scpTourService.hideDefaultTour();
      await this.sidenav.open();
    } else {
      await this.sidenav.close();
      if (this.restoreTour) {
        this.scpTourService.showDefaultTour(true);
      }
    }
  }

  public updateMenuState(category: Category, element: HTMLElement, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (element.classList.contains('scp-closed')) {
      element.classList.remove('scp-closed');
      element.classList.add('scp-opened');
      category.closed = false;
    } else {
      element.classList.remove('scp-opened');
      element.classList.add('scp-closed');
      category.closed = true;
    }
  }

  /* istanbul ignore next */
  protected onResize(scrollTop: number): void {
    this.zone.runOutsideAngular(() => {
      const isToTop = scrollTop > 700;
      const clazz = scrollTop > 0 && !this.isWeb ? 'scp-shadow' : null;
      this.top.nativeElement.style.visibility = isToTop ? 'visible' : 'hidden';
      if (clazz !== this.headerClass) {
        this.toTop = isToTop;
        this.top.nativeElement.classList.toggle(clazz, true);
      }
    });
  }

  protected menuTrackBy(item: CategoryItem): string {
    return item.title + item.count;
  }

  private initLayout(): void {
    this.processQueryParams();

    this.breakpointObserver.observe('(min-width: 961px)').subscribe((result: BreakpointState) => {
      this.zone.run(() => {
        if (result.matches !== this.isWeb) {
          this.isWeb = result.matches;
          this.changeDetectorRef.markForCheck();
        }
      });
    });

    this.breakpointObserver.observe('(max-width: 768px)').subscribe((result: BreakpointState) => {
      if (result.matches !== this.isFloatCart) {
        this.isFloatCart = result.matches;
        this.changeDetectorRef.markForCheck();
      }
    });

    let currentRoute: ActivatedRoute = this.activatedRoute;

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    this.router.events
      .pipe(
        filter((event) =>
          event instanceof NavigationEnd || (event instanceof NavigationCancel && (!event.reason || event.reason.includes('is not equal')))),
        map(() => this.activatedRoute),
        map((route) => {
          this.isAuthorized = this.storageService.isAuthorised();

          this.headerClass = null;

          while (route.firstChild) {
            route = route.firstChild;
          }

          this.changeDetectorRef.markForCheck();
          return route;
        }),
      )
      .subscribe((route: ActivatedRoute) => {
        const snapshot = route.snapshot;
        this.isVisibleSkeletonLoader = false;
        /* istanbul ignore next */
        if (snapshot.data) {
          this.updateLayoutAndTitle(snapshot.data);
        }
        this.tours = this.scpTourService.getTours(this.router.url);
        this.updateCookiesHeader();
        this.updateDefaultTourState();
      });

    this.scpTourService.update.subscribe(/* istanbul ignore next */() => this.tours = this.scpTourService.getTours(this.router.url));

    this.router.events
      .pipe(filter((event: NavigationStart) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        /* istanbul ignore next */
        this.isVisibleSkeletonLoader = true;
        if (this.isVisibleSkeletonLoader) {
          this.smartBannerConfig = null;
        }
        this.toTop = false;
        this.tours = [];
        this.updateLayout(currentRoute.snapshot.data.align, !this.isVisibleSkeletonLoader);
        this.updateCookiesHeader(event);
        this.updateDefaultTourState(event);
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof ResolveEnd),
        startWith([null]),
        pairwise(),
      )
      .subscribe((event: [ResolveEnd, ResolveEnd]) => {
        /* istanbul ignore else */
        if (
          !event[1].url.includes('#') &&
          event[0].url?.split('#')[0].split('?')[0] !== event[1].url.split('#')[0].split('?')[0]
        ) {
          this.layoutService.setTitle(null);
          this.layoutService.hideErrorNotification();
          this.updateCustomer();
        }
      });

    this.storageService.balance.subscribe((balance: Balance): void => {
      this.zone.run(() => {
        this.balance = balance;
        this.changeDetectorRef.markForCheck();
      });
    });

    this.storageService.getCustomer().subscribe(async (customer: Customer): Promise<void> => {
      await this.zone.run(async (): Promise<void> => {
        this.customer = customer;
        this.changeDetectorRef.markForCheck();
        await this.askSourceOfFunds();
      });
    });

    this.storageService.getOffers().subscribe((offers: number) => this.updateOffers(offers));
    this.updateLayoutAndTitle(currentRoute.snapshot.data);
    this.tours = this.scpTourService.getTours(this.router.url);
    this.updateCookiesHeader();

    this.layoutService.getPageData()
      .pipe(skip(1))
      .subscribe((seo: PageData | null) => this.updateSeo({ seo }));

    if (!this.isAuthorized) {
      this.updateCategories(false);
    }
  }

  /* istanbul ignore next */
  private async askSourceOfFunds(): Promise<void> {
    if (this.isBrowser && !this.sourceOfFunds && this.customer?.sourceOfFunds.required && !this.sourceOfFundsDialogRef) {
      this.sourceOfFunds = true;
      this.sourceOfFundsDialogRef = await this.sourceOfFundsDialogService.open();
      this.sourceOfFundsDialogRef.closed.pipe(first()).subscribe(() => {
        this.sourceOfFundsDialogRef = null;
        this.sourceOfFunds = false;
      });
    }
  }

  /* istanbul ignore next */
  private updateCookiesHeader(event?: NavigationStart) {
    if (event) {
      if (event.url.startsWith('/sign-') || event.url.startsWith('/forgot-')) {
        this.showCookiesHeader = false;
      }
    } else if (!this.router.url.startsWith('/sign-') && !this.router.url.startsWith('/forgot-')) {
      this.showCookiesHeader = !this.storageService.isCookiesAccepted() && this.isBrowser;
    } else {
      this.showCookiesHeader = false;
    }
  }

  /* istanbul ignore next */
  private updateDefaultTourState(event?: NavigationStart) {
    if (event) {
      if (event.url.startsWith('/sign-') || event.url.startsWith('/forgot-')) {
        this.hideDefaultTour();
      }
    } else if (!this.router.url.startsWith('/sign-') && !this.router.url.startsWith('/forgot-')) {
      if (this.restoreTourAfterNavigation && !this.tourTime && this.isBrowser) {
        this.zone.runOutsideAngular(() => this.tourTime = timer(10000).subscribe(() => {
          this.zone.run(() => {
            this.scpTourService.showDefaultTour(this.restoreTourAfterNavigation);
            this.restoreTourAfterNavigation = false;
            this.tourTime = null;
          });
        }));
      }
    } else if (!this.restoreTourAfterNavigation) {
      this.hideDefaultTour();
    }
  }

  /* istanbul ignore next */
  private hideDefaultTour() {
    this.restoreTourAfterNavigation = this.scpTourService.hideDefaultTour() || !!this.tourTime;
    this.tourTime?.unsubscribe();
    this.tourTime = null;
  }

  private processQueryParams(): void {
    if (!this.isAuthorized) {
      this.activatedRoute.queryParams.subscribe((params: Params) => {
        // utm tags
        const stag = params.stag;
        const hasStag: boolean = stag && !this.cookiesService.get('referrer');
        const paramsUtmSource = params.utm_source || (hasStag ? 'affilka' : undefined);
        const paramsUtmMedium = params.utm_medium || (hasStag ? 'affilka' : undefined);
        const paramsUtmCampaign = params.utm_campaign || (hasStag ? stag.split('_')[0] : undefined);
        if (paramsUtmSource || paramsUtmMedium || paramsUtmCampaign) {
          if (this.cookiesService.get('firstVisit')) {
            const lastVisitData = JSON.stringify({
              lastUtmVisit: Date.now(),
              lastUtmSource: paramsUtmSource,
              lastUtmMedium: paramsUtmMedium,
              lastUtmCampaign: paramsUtmCampaign,
            });
            this.cookiesService.put('lastVisit', lastVisitData, {
              expires: StorageService.getNextYearDate(),
            });
          } else {
            const firstVisitData = JSON.stringify({
              firstUtmVisit: Date.now(),
              firstUtmSource: paramsUtmSource,
              firstUtmMedium: paramsUtmMedium,
              firstUtmCampaign: paramsUtmCampaign,
              lastUtmVisit: Date.now(),
            });
            this.cookiesService.put('firstVisit', firstVisitData, {
              expires: StorageService.getNextYearDate(),
            });
          }
        }

        // referrer friend
        if (!this.cookiesService.get('referrer')) {
          const paramsReferrer = params.referrer;
          const paramsSource = params.source;
          const paramsClickId = params.clickid;
          const paramsOfferId = params.offer_id;
          const paramsPartnerId = params.partner_id;
          if (paramsReferrer && paramsSource) {
            const referrerObj = JSON.stringify({
              customerId: paramsReferrer,
              source: paramsSource,
              date: Date.now(),
            });
            this.cookiesService.put('referrer', referrerObj, {
              expires: StorageService.getNextYearDate(),
            });
          } else if (paramsClickId) {
            const customerId: string = 'AFFISE:' + paramsClickId;
            const offerId: string | null = paramsOfferId ? '#OFFER:' + paramsOfferId : '';
            const partnerId: string | null = paramsPartnerId ? '#PARTNER:' + paramsPartnerId : '';
            let id: string = customerId + offerId + partnerId;
            for (let i = 1; i < 8; i++) {
              const value: string | undefined = params['sub' + i];
              if (value) {
                id += `#SUB${i}:${value}`;
              }
            }
            const referrerObj: string = JSON.stringify({
              offerId: paramsOfferId,
              customerId: id,
              source: 'AFFISE',
              date: Date.now(),
            });
            this.cookiesService.put('referrer', referrerObj, {
              expires: StorageService.getNextMonthDate(),
            });
          } else if (stag) {
            const referrerObj: string = JSON.stringify({
              customerId: 'AFFILKA:' + stag,
              source: 'AFFILKA',
              date: Date.now(),
            });
            this.cookiesService.put('referrer', referrerObj, {
              expires: StorageService.getNextMonthDate(),
            });
          }
        }
      });
    }
  }

  private updateCategories(reset: boolean): void {
    if (reset) {
      this.menuCategories = null;
    }
    if (!this.menuCategories) {
      if (this.isAuthorized) {
        this.menuCategories = DashboardComponent.createCategories(this.configService);
        this.storageService.getWithdrawalConfiguration()
          .pipe(take(1), filter(a => !!a))
          .subscribe((config) => {
            this.menuCategories[0].items[2].isActive = !!config.methods.find(m => m.withdrawalType === WithdrawalType.CREDIT_TOKEN);
            this.menuCategories.forEach((category) => {
              category.items?.forEach((item) => {
                if (this.router.url.includes(item.routerLink)) {
                  category.closed = false;
                }
              });
            });
            this.zone.run(() => this.changeDetectorRef.markForCheck());
          });
        if (this.isBrowser) {
          this.idle.requestIdleCallback(() => {
            forkJoin([
              this.scpService.getBonusOffersStats(),
              this.scpService.getBalance(),
            ]).subscribe((data: [BonusProgramOffersStats, Balance]): void => {
              if (data[0].referrerOffer) {
                const item = this.menuCategories.find(m => m.title === 'REFER_A_FRIEND');
                item.isActive = true;
                item.routerLink = item.routerLink + data[0].referrerOffer.id;
              }
              this.storageService.setOffers(data[0].active);
              this.storageService.setBalance(data[1]);
              this.zone.run(() => this.changeDetectorRef.markForCheck());
            });
          });
        }
      } else {
        this.menuCategories = DashboardComponent.createNotAuthorizedCategories(this.configService);
      }
    }
  }

  private updateCustomer(reset: boolean = false): void {
    if (this.isAuthorized) {
      this.storageService.getCustomer()
        .pipe(filter((user: Customer): boolean => !!user), first())
        .subscribe(async (user: Customer): Promise<void> => {
          this.customer = user;
          this.updateCategories(reset);
          this.isLoaded = true;
        });
    }
  }

  private async updateAuthorizationStatus(isAuthorized: boolean): Promise<void> {
    const reset = this.isAuthorized !== isAuthorized;
    this.isAuthorized = isAuthorized;
    this.updateCustomer(reset);
    this.changeDetectorRef.markForCheck();
    if (isAuthorized && this.isBrowser) {
      this.scpWebSocketService.on('balance').subscribe((balance: string) => {
        this.zone.run(() => this.storageService.setBalance(JSON.parse(balance)));
      });
      this.scpWebSocketService.on('profile').subscribe((customer: string) => {
        this.zone.run(() => this.storageService.setCustomer(JSON.parse(customer)));
      });
      this.scpWebSocketService.on('facebook').subscribe(() => {
        /* istanbul ignore next */
        if ((parseJWT(this.storageService.accessToken).payload.sub as string)?.includes('|facebook|')) {
          this.signOut();
          this.router.navigateByUrl('/sign-in');
        }
      });
    }
  }

  private updateLayout(align: 'top' | 'center', fullWidth: boolean, hasFooter: boolean = false): void {
    const classes = [];
    if (align) {
      classes.push(align);
    }
    if (fullWidth) {
      classes.push('full-width');
    }
    this.hasFooter = hasFooter;
    this.layoutClass = classes.length > 0 ? classes.join(' ') : null;
  }

  private updateLayoutAndTitle(data: Data): void {
    const { align, fullWidth, hasFooter } = data;
    this.updateLayout(align, fullWidth, hasFooter);
    const url: string = this.router.url.split('?')[0];
    this.isFloatCartAllowed = ![
      '/account/profile/kyc/documents',
      '/account/profile/kyc/documents/not-resident',
    ].includes(url);
    if (!this.activatedRoute.firstChild?.firstChild?.snapshot.data.ignoreSEO) {
      this.updateSeo(data);
    }
  }

  private updateOffers(offers: number): void {
    if (this.menuCategories) {
      const category = this.menuCategories.find((item) => item.title === 'MY_BONUSES');
      category.items[0].count = offers;
      this.changeDetectorRef.markForCheck();
    }
  }

  private updateSeo(data: Data): void {
    if (data.seo) {
      this.updateMetaSeo(data.seo.title, data.seo.description);
      this.seo = data.seo.seoText;
      this.smartBannerConfig = data.seo.smartBanner;
    } else {
      this.seo = null;
      this.smartBannerConfig = null;
      this.updateMetaSeo(this.configService.defaultPageTitle, this.configService.defaultPageDescription);
    }
    this.updateSnippets();
  }

  private updateMetaSeo(title: string, description: string): void {
    this.title.setTitle(title);
    const url = this.host + this.router.url;
    this.meta.removeTag('name=\'description\'');
    this.meta.addTag({ name: 'description', content: description }, true);
    for (const tag of this.configService.meta) {
      let content = tag.content;
      if (content === '<title>') {
        content = title;
      } else if (content === '<description>') {
        content = description;
      } else if (content === '<url>') {
        content = url;
      }
      if (tag.name) {
        this.meta.removeTag(`name='${tag.name}'`);
        this.meta.addTag({ name: tag.name, content }, true);
      } else {
        this.meta.removeTag(`property='${tag.property}'`);
        this.meta.addTag({ property: tag.property, content }, true);
      }
    }
  }

  private updateSnippets() {
    for (const snippet of this.configService.snippets) {
      const el: HTMLElement = this.document.getElementById(snippet.id);
      if (!snippet.pages || this.router.url.match(snippet.pages)) {
        if (!el) {
          const script = this.document.createElement('script');
          script.id = snippet.id;
          script.type = snippet.contentType;
          script.text = snippet.content;
          ((snippet.location === 'head') ? this.document.head : /* istanbul ignore next */ this.document.body).appendChild(script);
        }
      } else /* istanbul ignore if */ if (el) {
        el.remove();
      }
    }
  }

}
