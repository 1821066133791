import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@scpc/modules/common/components/button/button.component';

@Component({
  selector: 'scp-header-cookies',
  templateUrl: './header-cookies.component.html',
  styleUrls: ['./header-cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ButtonComponent, RouterLink],
})
export class HeaderCookiesComponent {

  @Output()
  public accepted: EventEmitter<void> = new EventEmitter<void>();

  public acceptCookies(): void {
    this.accepted.next();
  }

}
