import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { LayoutService } from '@scpc/modules/common/services';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconAnchor } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'scp-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, TranslateModule, MatIconAnchor],
})
export class TitleComponent implements OnInit {

  @Input()
  public isHiddenTitle: boolean;

  public readonly siteName: string = this.configService.siteName;
  public title: string | null;
  public back: string | null;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private readonly layoutService: LayoutService,
              private readonly configService: ConfigService,
              private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.layoutService.getTitle().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((title) => {
      this.title = title;
      this.changeDetectorRef.detectChanges();
    });
    this.layoutService.getBack().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((back) => {
      this.back = back;
      this.changeDetectorRef.detectChanges();
    });
  }

}
