import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ConfigService, Product } from '@scpc/modules/common/services/config.service';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import Swiper from 'swiper';
import { catchError } from '@scpc/utils/dom.utils';
import { MatIconButton } from '@angular/material/button';
import { isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FreeMode } from 'swiper/modules';

@Component({
  selector: 'scp-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, MatIconButton, RouterLink, RouterLinkActive],
})
export class ProductsComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input()
  public type: 'mobile' | 'desktop';

  protected readonly products: Product[] = this.configService.products.filter((p: Product): boolean => p.hidden !== true);
  protected index: number = -1;
  protected reachBeginning = true;
  protected reachEnd = true;
  protected swiper: Swiper;
  private destroyRef: DestroyRef = inject(DestroyRef);
  private isBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(
    public readonly router: Router,
    private readonly configService: ConfigService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {
  }

  public ngOnInit(): void {
    this.reachEnd = this.type === 'desktop';
    this.scrollToProduct(false);
  }

  public ngAfterContentInit(): void {
    this.router.events
      .pipe(filter(/* istanbul ignore next */event => event instanceof NavigationEnd), takeUntilDestroyed(this.destroyRef))
      .subscribe(/* istanbul ignore next */() => this.scrollToProduct(true));
    this.swiper = new Swiper('.swiper-products-' + this.type, {
      init: this.isBrowser,
      initialSlide: this.index,
      modules: [FreeMode],
      freeMode: true,
      touchRatio: 1.5,
      speed: 400,
      grid: {
        rows: 1,
      },
      resistance: true,
      resistanceRatio: 0.75,
      centeredSlides: true,
      centeredSlidesBounds: true,
      slidesPerView: 'auto',
      watchSlidesProgress: true,
      resizeObserver: true,
      updateOnWindowResize: true,
      centerInsufficientSlides: false,
      on: {
        afterInit: /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
        reachBeginning: /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
        reachEnd: /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
        resize: /* istanbul ignore next */ (swiper: Swiper): void => {
          this.updateSlider(swiper);
          swiper.slideTo(this.index);
        },
        setTranslate:  /* istanbul ignore next */(swiper: Swiper, translate: number) => {
          if (!Number.isNaN(translate)) {
            this.updateSlider(swiper);
          }
        },
      },
    });
  }

  public ngOnDestroy(): void {
    /* istanbul ignore else */
    catchError(() => this.swiper?.destroy());
  }

  /* istanbul ignore next */
  private updateSlider(swiper: Swiper): void {
    const hasOverflow: boolean = Math.floor((swiper as any).virtualSize) > swiper.width;
    this.reachBeginning = swiper.isBeginning || !hasOverflow;
    this.reachEnd = swiper.isEnd || !hasOverflow;
    this.changeDetectorRef.markForCheck();
  }

  private scrollToProduct(scroll: boolean): void {
    for (let i = 0; i < this.products.length; i++) {
      const product = this.products[i];
      if (product.link.startsWith('/') && this.router.isActive(product.link, {
        paths: product.link === '/' ?  /* istanbul ignore next */ 'exact' : 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
      })) {
        /* istanbul ignore next */
        if (scroll && this.isBrowser && this.swiper.wrapperEl) {
          this.swiper?.slideTo(i, 400, false);
        } else {
          this.index = i;
        }
        break;
      }
    }
  }

}
